/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import useInterval from '@use-it/interval';

// Constants
const VALID_CHARS = `アカサナハヤャラガザダパイィシチニリヰギヂビピゥクスヌフムュルグブヅプェケセネヘメヱゲデベペォコトノホヨョロゴゾドポヴッン`
	+ `０１２３４５６７８９ＡＢＣＤＥＦＧＨＩＪＫＬＭＮＯＰＱＲＳＴＵＶＷＸＹＺ`;
const STREAM_MUTATION_ODDS = 0.06;

const MIN_STREAM_SIZE = 20;
const MAX_STREAM_SIZE = 30;

const MIN_INTERVAL_DELAY = 35*2;
const MAX_INTERVAL_DELAY = 40*6;

const MIN_DELAY_BETWEEN_STREAMS = 0;
const MAX_DELAY_BETWEEN_STREAMS = 20000;
const FONT_SIZE = 18; // 請一併更新 index.css 的 span height
const CHAR_GAP = 2;
const INITIAL_TOP_PADDING_BASE = -1 * (FONT_SIZE+CHAR_GAP); 

const getRandInRange = (min, max) =>
	Math.floor(Math.random() * (max - min)) + min;

const getRandChar = () =>
	VALID_CHARS.charAt(Math.floor(Math.random() * VALID_CHARS.length));

const getRandStream = () =>
	new Array(getRandInRange(MIN_STREAM_SIZE, MAX_STREAM_SIZE))
		.fill()
		.map(_ => getRandChar());

const getMutatedStream = stream => {
	const newStream = [];
	for (let i = 1; i < stream.length; i++) {
		newStream.push( (Math.random() < STREAM_MUTATION_ODDS) ? getRandChar() : stream[i]); // 依機率變化內文
	}
	newStream.push(getRandChar());
	return newStream;
};

const RainStream = props => {
	const [stream, setStream] = useState(getRandStream());
	const [topPadding, setTopPadding] = useState((stream.length + 1) * INITIAL_TOP_PADDING_BASE);
	const [intervalDelay, setIntervalDelay] = useState(null);

	useEffect(() => {
		setTimeout(() => {
		  setIntervalDelay(getRandInRange(MIN_INTERVAL_DELAY, MAX_INTERVAL_DELAY));
		}, getRandInRange(MIN_DELAY_BETWEEN_STREAMS, MAX_DELAY_BETWEEN_STREAMS));
	}, []);

	useInterval(() => {
		if (!props.height) return;
		if (!intervalDelay) return;

		// If stream is off the screen, reset it after timeout
		if (topPadding > props.height) {
			setStream([]);
			const newStream = getRandStream();
			setStream(newStream);
			setTopPadding((newStream.length + 1) * INITIAL_TOP_PADDING_BASE);
			setIntervalDelay(null);
			setTimeout(
				() =>
					setIntervalDelay(
						getRandInRange(MIN_INTERVAL_DELAY, MAX_INTERVAL_DELAY),
					),
				getRandInRange(MIN_DELAY_BETWEEN_STREAMS, MAX_DELAY_BETWEEN_STREAMS),
			);
		} else {
			setTopPadding(topPadding + FONT_SIZE + CHAR_GAP);
		}
		// setStream(stream => [...stream.slice(1, stream.length), getRandChar()]);
		setStream(getMutatedStream);
	}, intervalDelay);

	return (
		<div className='stream'	style={{
			marginTop: topPadding, 
			height: stream.length * (FONT_SIZE+CHAR_GAP),
		}}>
			{stream.slice(0, -1).join('')}<span	key={uuidv4()}>{stream.slice(-1)}</span>
		</div>
	);
};

const MatrixRain = props => {
	const containerRef = useRef(null);
	const [containerSize, setContainerSize] = useState(null); // ?{width, height}

	useEffect(() => {
		const boundingClientRect = containerRef.current.getBoundingClientRect();
		setContainerSize({
			width: boundingClientRect.width,
			height: boundingClientRect.height,
		});
	}, []);

	const streamCount = containerSize ? Math.floor(containerSize.width / 18) : 0;

	return (
		<div
		  className='container'
			style={{fontSize: FONT_SIZE, letterSpacing: CHAR_GAP}}
			ref={containerRef}>
			{new Array(streamCount).fill().map(_ => (
				<RainStream key={uuidv4()} height={containerSize?.height} />
			))}
		</div>
	);
};

export default MatrixRain;
