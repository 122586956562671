import React from 'react';
import { createRoot } from 'react-dom/client'; // 注意这里是新的导入方法
import './index.css';
import App from './App';

// 找到页面中的 'root' 元素
const rootElement = document.getElementById('root');

// 使用 createRoot 初始化 root，并调用 render 方法来渲染 <App />
createRoot(rootElement).render(<App />);
